import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import Perm from '@/router/permission';
import PermCode from '@/router/perm-code';
import { Loading } from '@cloudpivot-hermes/common-components';
import { namespace } from 'vuex-class';
const Organization = namespace('Organization');
const sysManage = PermCode.sysManage;
let SystemManage = class SystemManage extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.timer = 0;
        this.menus = [
            {
                name: '组织管理',
                code: sysManage.orgManage,
                routeName: 'org-manage',
                icon: 'h-icon-all-user-o',
                children: [],
            },
            {
                name: '数据字典',
                code: sysManage.dataDictionary,
                routeName: 'sys-data-dictionary',
                icon: 'h-icon-all-user-o',
                children: [],
            },
            {
                name: '权限管理',
                code: sysManage.permManage,
                routeName: 'perm-manage',
                icon: 'h-icon-all-department-o',
                children: [],
            },
            {
                name: '安全设置',
                code: sysManage.safeConfig,
                routeName: 'safe-config',
                icon: 'h-icon-all-department-o',
                children: [
                    {
                        name: '密码规则',
                        code: sysManage.passwordRule,
                        routeName: 'password-rule',
                        icon: 'h-icon-all-lock-o1',
                        children: [],
                    },
                ],
            },
            {
                name: '日志消息',
                code: sysManage.logMessage,
                routeName: 'label-manage',
                icon: 'h-icon-all-tag-o',
                children: [
                    {
                        name: '应用操作日志',
                        code: sysManage.appOperateLog,
                        routeName: 'app-operate-log',
                        icon: 'h-icon-all-process-o',
                        children: [],
                    },
                    {
                        name: '应用管理日志',
                        code: sysManage.appManageLog,
                        routeName: 'app-manage-log',
                        icon: 'h-icon-all-tag-o',
                        children: [],
                    },
                    {
                        name: '登录日志',
                        code: sysManage.loginLog,
                        routeName: 'login-log',
                        icon: 'h-icon-all-tag-o',
                        children: [],
                    },
                    {
                        name: '组织权限管理日志',
                        code: sysManage.orgPermManageLog,
                        routeName: 'org-permission-log',
                        icon: 'h-icon-all-tag-o',
                        children: [],
                    },
                    {
                        name: '密码修改日志',
                        code: sysManage.passwordUpdateLog,
                        routeName: 'password-update-log',
                        icon: 'h-icon-all-tag-o',
                        children: [],
                    },
                ],
            },
            {
                name: '公共服务扩展管理',
                code: sysManage.commonServiceExtension,
                routeName: 'common-service-extension',
                icon: 'h-icon-all-department-o',
                children: [],
            },
        ];
    }
    get showSider() {
        return this.orgCount;
    }
    onCheckOrgLoadingChange() {
        if (this.checkOrgLoading) {
            this.timer = setTimeout(() => {
                this.loading = this.checkOrgLoading;
            }, 1000);
        }
        else {
            clearTimeout(this.timer);
        }
    }
    get siderMenus() {
        return Perm.filterMenus(this.menus);
    }
};
__decorate([
    Organization.State('orgCount')
], SystemManage.prototype, "orgCount", void 0);
__decorate([
    Organization.State('checkOrgLoading')
], SystemManage.prototype, "checkOrgLoading", void 0);
__decorate([
    Watch('checkOrgLoading', { immediate: true })
], SystemManage.prototype, "onCheckOrgLoadingChange", null);
SystemManage = __decorate([
    Component({
        name: 'SystemManage',
        components: {
            Sider: () => import('@/components/global/sider/index.vue'),
            Loading,
        },
    })
], SystemManage);
export default SystemManage;
